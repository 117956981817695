<template>
    <div>
        <v-data-table :dense="props.dense" :key="props.keyValue" disable-pagination hide-default-footer :loading="props.loading" :class="`elevation-0 ${props.loading ? '': 'table-b-border-item'}`" no-results-text="No hay resultados" no-data-text="No hay datos" :headers="props.headers" :items="items" >
            <template v-slot:[`item.actions`]="{ item }">
                <v-tooltip top v-for="(action, i) of item.actions" :key="i">
                    <template v-slot:activator="{ on }">
                        <v-btn x-small v-if="action.type === 'method'" class="mx-0" icon @click="methodHandler(action.action, item.parameters)" :disabled="action.disabled">
                            <v-icon :color="action.icon.color" v-on="on">{{ action.icon.icon }}</v-icon>
                        </v-btn>
                        <v-btn x-small v-else-if="action.type === 'redirect'" icon text :to="action.action" :disabled="action.disabled">
                            <v-icon :color="action.icon.color" v-on="on">{{ action.icon.icon }}</v-icon>
                        </v-btn>
                        <v-btn x-small v-else-if="action.type === 'external'" icon text target="_blank" :href="action.action" :disabled="action.disabled">
                            <v-icon :color="action.icon.color" v-on="on">{{ action.icon.icon }}</v-icon>
                        </v-btn>
                    </template>
                    <span>{{ action.name }}</span>
                </v-tooltip>
            </template>
            <template
                v-for="slot in Object.keys($scopedSlots)"
                :slot="slot"
                slot-scope="scope"
                ><slot :name="slot" v-bind="scope"
            /></template>
        </v-data-table>

    </div>
</template>

<script>
    export default {
    
        name: 'datatable',
        props: ['props', 'items'],
        methods: {
            methodHandler(methodName, parameters){
                
                this.$emit('methodHandler', {
                    methodName: methodName, 
                    parameters: parameters
                });
            },
            
        }
    }
</script>

<style>

</style>
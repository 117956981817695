<template>
    <contentCard :loading="loading">
        <template slot="cardContent">
            <div>
                <v-row justify="center">
                    <v-col class="text-center">
                        <div class="text-uppercase font-weight-bold primary--text text-h5">
                            Registrar nuevo rol
                        </div>
                    </v-col>
                </v-row>
                <v-row justify="center">
                    <v-col cols="12" md="11" lg="9" xl="7">
                        <v-form ref="form" @submit.prevent="save">
                            <rol-form ref="RolForm" :values="form" @save="save"></rol-form>
                        </v-form>
                    </v-col>
                </v-row>
            </div>
			
            <v-row justify="center" align="end">
				<v-col cols="12" md="11" lg="9" xl="7" class="text-right">
					<v-btn class="full-width-btn" tile text color="primary" :to="{ name: 'Roles' }">Cancelar</v-btn>
					<v-btn tile color="secondary" class="ml-md-2 ml-0 mt-md-0 mt-3 full-width-btn text-base-color" @click="save">Guardar</v-btn>
				</v-col>
			</v-row>
        <snackbar :props="snackbarProps"></snackbar>
        </template>
    </contentCard>
</template>

<script>
import RolForm from "./Form.vue";
import permissions from "./permission";
import modules from './modules';
export default {
    components: {
        'rol-form': RolForm,
    },
	data(){
		return {
            loading: false,
            form: {
                name: "",
                permissions: permissions,
            },
            
            snackbarProps: {
                visible: false,
				color: 'success',
				timeout: 3000,
				text: '',
            },
		}
    },
    mounted(){
        this.init();
    },
	methods: {
		validate () {
			return this.$refs.form.validate()
		},
        init()
        {
            for (const property in this.form.permissions) {
                this.form.permissions[property] = {};
            }
        },
        save(){
            this.$store.state.overlay = true

            if(this.validate()){               
               
                this.$http.post(this.$store.state.apiRoute+'/roles', this.prepareRequest(this.form))
                .then(() => {
                    // response.data

                    this.$store.state.globalSnackbarProps.visible = true
                    this.$store.state.globalSnackbarProps.text = 'Se ha registrado correctamente</b>'
                    this.$router.push({name: 'Roles'})
                })
                .catch((error) => {
                    var errors = []
                    switch(error.response.status){
                        case 422: 
                            Object.keys(error.response.data.errors).forEach((x) => {
                                error.response.data.errors[x].forEach((y) => {
                                    errors.push(y)
                                })
                            })
                        break;
                        case 500: 
                            errors.push('Error en el servidor. Intente de nuevo.')
                        break;
                        case 404: 
                            errors.push('No encontrado.')
                        break;
                    }

                    this.snackbarProps = {
                        visible: true,
                        color: 'error',
                        timeout: 10000,
                        text: errors.join('.<br>'),
                    }
                })
                .finally(() => {
                    this.$store.state.overlay = false
                })
            }else{
                this.$refs.RolForm.moveStep(1)
                this.snackbarProps = {
                    visible: true,
                    color: 'error',
                    timeout: 2000,
                    text: 'Formulario incompleto',
                }

                this.$store.state.overlay = false
            }
            // eslint-disable-next-line no-console
        },

        prepareRequest(request)
        {
            let data = JSON.parse(JSON.stringify(request));

            for (const indexModule in modules) {
                for(const submoduleIndex in modules[indexModule].submodules){
                    if("notIn" in modules[indexModule].submodules[submoduleIndex]) {
                        for(const notInIndex in modules[indexModule].submodules[submoduleIndex].notIn)
                            delete data.permissions[modules[indexModule].submodules[submoduleIndex].key][modules[indexModule].submodules[submoduleIndex].notIn[notInIndex]]
                    }
                }
            }

            return data;
        }
    },
}
</script>

<style>

</style>